















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";

import { MeasureProcessStepFinding } from "@/store/modules/measureProcess/types";
import AAuditOverviewFinding from "@/components/widgets/AAuditOverviewFinding.vue";

@Component({
  components: {
    AAuditOverviewFinding,
  },
})
export default class AMeasureProcessStepFinding extends Vue {
  //missingDefault
  @Prop({
    type: MeasureProcessStepFinding,
  })
  readonly doc!: MeasureProcessStepFinding;
  created() {
    console.log("AMeasureProcessStepFinding:created", this.doc);
  }
}
